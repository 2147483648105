:root {
    --primary-color:#263238;
    --secondary-color:#ff5722;
    --danger-color: #e53935;
}

body{
  margin: auto;
  color: #303030;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.material-icons.ml-8{
  margin-right: 8px;
}
.material-icons.mr-8{
  margin-right: 8px;
}
.material-icons.primary, .fas.primary{
  color: var(--primary-color);
}
.material-icons.secondary , .fas.secondary {
  color: var(--secondary-color);
}
.material-icons.danger, .fas.danger{
  color: var(--danger-color);
}
.material-icons.inherit, .fas.inherit{
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.light, .fas.light{
  color: white;
}

.material-icons.size-24, .fas.size-24 { font-size: 24px; }
.material-icons.size-32, .fas.size-32 { font-size: 32px; }
.material-icons.size-40, .fas.size-40 { font-size: 40px; }
.material-icons.size-48, .fas.size-48{ font-size: 48px; }

#body-wrapper #left-drawer-wrapper{
  display: flex;
}
#body-wrapper #workspace-wrapper{
  padding: initial;
}

.w-md-editor{
  border-radius:4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow:none ;
  width: 100% 
}
.w-md-editor-text :focus {
    outline: -webkit-focus-ring-color auto;
}
.ribbon {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
  background-color: rgba(170, 0, 0, 0.5);
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
  position: fixed;
  top: 35px;
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.75;
}



@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 1200px) and (max-height: 600px) { 
        body{zoom: 80%;}
}
